<template>
  <div class="app-dialog-header">
    <div class="app-dialog-header-title">
      <span>Select Tokens</span>
    </div>
    <div class="app-dialog-header-action">
      <span @click="closeDialog()">&times;</span>
    </div>
  </div>
  <div class="app-dialog-body">
    <div class="new-token-wrapper">
      <label for="address">New token:</label>
      <input
        placeholder="custom token"
        name="address"
        id="address"
        v-model.trim="newAddress"
        @keyup.enter="submitAddress(newAddress, 1)"
      />
    </div>
    <ul class="app-list">
      <li
        v-for="(coin, index) in $store.state.coins"
        :key="coin.address"
        class="app-list-item"
        @click="submitAddress(coin.address, 0)"
      >
        <div
          v-if="
            showThese[index] &&
            this.liqDialogVal.DialnumAdd[this.NSDN] != coin.address
          "
        >
          {{ coin.abbr }}
          <br />
          <small>{{ coin.name }}</small>
          <small class="ABU" v-if="coin.addedByUser"> | Added by user</small>
          <span style="float: right">
            <small>{{ coin.balance }}</small>
          </span>
        </div>
        <div v-else-if="showThese[index]" class="show-unselectable">
          {{ coin.abbr }}
          <br />
          <small>{{ coin.name }}</small>
          <small class="ABU" v-if="coin.addedByUser"> | Added by user</small>
          <span style="float: right">
            <small>{{ coin.balance }}</small>
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import * as COINS from "../../constants/coins.js";
import * as ethFunc from "../../ethereumFunctions.js";
// import web3 from "../../../ethereum/web3.js";
import swal from "sweetalert";

export default {
  props: ["swapDialNum"],
  data() {
    return {
      newAddress: null,
      showThese: [],
      NSDN: this.swapDialNum ? 0 : 1,
    };
  },
  methods: {
    ...mapActions({ closeDialog: "closeLiqDialog" }),
    async submitAddress(tokenAddress, action) {
      if (tokenAddress === this.liqDialogVal.DialnumAdd[this.NSDN]) {
        // swal("Alert", "You cannot select the same token", "warning");
        // console.log("You cannot select the same token");
      } else {
        try {
          await ethFunc
            .getBalanceandSymbol(this.$store.state.account0, tokenAddress, true)
            .then((data) => {
              if (data) {
                // if (this.swapDialNum === 1) {
                //   this.$route.params.add1 = tokenAddress;
                // } else {
                //   this.$route.params.add2 = tokenAddress;
                // }
                // const path =
                //   "/addLiquidity/" +
                //   this.$route.params.add1 +
                //   "/" +
                //   this.$route.params.add2;
                this.$store.commit("resetAddLiqState");
                this.liqTokenSymbolVal[this.swapDialNum] = data.symbol;
                this.liqDialogVal.DialnumAdd[this.swapDialNum] = tokenAddress;
                this.$store.dispatch("displayMaxTokenBalanceLiq", {
                  add: tokenAddress,
                  ind: this.swapDialNum,
                });
                if (action === 1) {
                  let addToken = true;
                  for (let i = 0; i < this.$store.state.coins.length; ++i) {
                    if (this.$store.state.coins[i].address === tokenAddress) {
                      addToken = false;
                    }
                  }
                  // console.log(data);
                  if (addToken) {
                    this.$store.state.coins.unshift({
                      name: data.name,
                      abbr: data.symbol,
                      address: tokenAddress,
                      balance: data.balance,
                      addedByUser: true,
                      marker: false,
                    });
                  }
                }
                this.$router.push(
                  `/addLiquidity/${this.liqDialogVal.DialnumAdd[0]}/${this.liqDialogVal.DialnumAdd[1]}`
                );
                // console.log(this.$router);
                this.$store.dispatch("displayReservesPool");
              } else {
                swal("Error", "Enter a valid token address", "error");
              }
            });
        } catch (err) {
          console.log("Invalid token address!");
        }
        this.$store.dispatch("closeLiqDialog");
      }
    },
  },
  computed: {
    ...mapGetters({
      liqTokenSymbolVal: "getLiqTokenSymbol",
      liqDialogVal: "getLiqDialog",
    }),
  },
  mounted() {
    for (let i = 0; i < this.$store.state.coins.length; i++) {
      this.showThese.push(true);
    }
  },
  watch: {
    newAddress(newVal) {
      this.showThese = [];
      if (newVal.length) {
        const filter = newVal.toUpperCase();
        for (let i = 0; i < this.$store.state.coins.length; i++) {
          const a = this.$store.state.coins[i].name;
          if (a.toUpperCase().indexOf(filter) > -1) {
            this.showThese.push(true);
          } else {
            this.showThese.push(false);
            // const adc = this.$store.state.coins[i].address.length;
            // if (
            //   newVal.length === adc &&
            //   adc.toUpperCase().indexOf(filter) > -1
            // ) {
            //   this.showThese[i] = true;
            // }
          }
        }
      } else {
        for (let i = 0; i < this.$store.state.coins.length; i++) {
          this.showThese.push(true);
        }
      }
      // console.log(this.showThese);
    },
  },
};
</script>

<style scoped>
.ABU {
  font-size: 0.8rem;
  color: #333333;
  font-weight: 400;
}
.new-token-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-bottom: 1rem;
  border-bottom: var(--element-border);
}
.new-token-wrapper label {
  white-space: nowrap;
}
</style>
